

const ErrorPage = ({ statusCode, statusText }) => {
    return (
        <div className="error">
            <h4 className="error__status">An error has occured with status code: <b>{statusCode}</b> ({statusText}).</h4>
            <p className="error__message">Please refresh the page or try again later.</p>
        </div>
    )
}

export default ErrorPage