import ArticlesList from "../../components/ArticlesList/ArticlesList"
import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton"


const Magazine = () => {
    return (
        <section className="magazine container">
            <h1 className="magazine__header">MAGAZYN</h1>
            <ArticlesList />
            <ScrollTopButton />
        </section>
    )
}

export default Magazine