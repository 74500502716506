import { useParams } from "react-router-dom"
import { 
    API_ENDPOINTS,
    BASE_URL
} from '../../settings'
import useFetchData from '../../hooks/useFetchData'

import Loader from "../../components/Loader/Loader"
import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton"
import ProductsWidget from '../../components/ProductsWidget/ProductsWidget'


const PostOverview = () => {
    const { slug } = useParams()
    const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.posts + `/${slug}`)

    const convertDate = (date) => {
        return new Date(date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }).split('/').join(' / ')
    }

    return (
        <div className="post-overview container p-0 d-flex flex-column">
            {isLoading && <Loader />}
            {error && <div>{error}</div>}
            {data &&
                <>
                    <div className="post-header d-flex flex-column align-items-center">
                        <h1 className="post-header__title">{data.title.toUpperCase()}</h1>
                        <p className="post-header__date">{convertDate(data.date)}</p>
                        <div className="post-header__separator"></div>
                        <img className="post-header__image" src={data.main_image} alt={data.alt} />
                    </div>
                    <div className="post-body">
                        <h1 className="post-body__title text-center">{data.title}</h1>
                        <div className="post-body__text d-flex flex-column" dangerouslySetInnerHTML={{ __html: data.content }}></div>
                        <div className="post-body__products">
                            <ProductsWidget
                                items={data.products} 
                                itemsPerPage={3}
                            />
                        </div>
                        <div className="post-body__images d-flex flex-column">
                            {data.images.map(image =>
                                <img className="post-body__image" src={image.image} alt={image.alt} loading="lazy" />
                            )}
                        </div>
                    </div>
                </>
            }
            <ScrollTopButton />
        </div>
    )
}

export default PostOverview