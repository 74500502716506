

const HomepageCard = ({ imageSrc, title, copyright }) => {
    return (
        <div className="homepage-card d-flex justify-content-center">
            <img src={imageSrc} alt="" className="homepage-card__image" />
            <div className="homepage-card__text">{title}</div> 
            <span className="homepage-card__copyright">&copy;{copyright}</span>
        </div>
    )
}

export default HomepageCard