import { NAVIGATION } from "../../constants/navigation"

import { Link } from "react-router-dom"
import Logo from "../Logo/Logo"
import SocialMedia from "../SocialMedia/SocialMedia"
import OverlayNavigationListItem from './OverlayNavigationListItem'
import { IconContext } from 'react-icons';
import { BsFillSuitHeartFill } from 'react-icons/bs'


const OverlayNavigation = ({ overlayNavigationHeight, closeOverlayNavigationOnClick }) => {
    return (
        <div className="overlay-navigation" style={{height: overlayNavigationHeight}}>
            <i className="overlay-navigation__close-button fas fa-times fa-2x" onClick={closeOverlayNavigationOnClick}></i>
            <div className="overlay-navigation__content">
                <ul className="overlay-navigation__list">
                    {NAVIGATION.map((category, index) => {
                        return (
                            <OverlayNavigationListItem 
                                key={index} 
                                href={category.path} 
                                itemLinkText={category.name} 
                                subcategories={category.subcategories}
                                closeOverlayNavigationOnClick={closeOverlayNavigationOnClick} 
                            />
                        )
                    })}
                    {/* <OverlayNavigationListItem 
                        href="/gift-guide"
                        itemLinkText="Walentynki" 
                        icon={
                            <IconContext.Provider value={{ style: { fontSize: '30px', padding: '0 5px' } }} >
                                <BsFillSuitHeartFill />   
                            </IconContext.Provider>
                        }
                        closeOverlayNavigationOnClick={closeOverlayNavigationOnClick} 
                    /> */}
                </ul>
                <div className="overlay-navigation__logo">
                    <Link to='/' onClick={closeOverlayNavigationOnClick}>
                        <Logo type="primary" />
                    </Link>
                </div>
                <div className="overlay-navigation__social-media d-flex justify-content-center">
                    <SocialMedia />
                </div>
            </div>
        </div>
    )
}

export default OverlayNavigation