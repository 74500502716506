import { Link } from "react-router-dom"


const ArticleCard = ({ title, author, date, url, imageUrl, shortContent }) => {
    return (
        <div className="article-card shadow-sm">
            <Link to={url} className="d-flex flex-column">
                <div className="article-card__header">
                    <img src={imageUrl} alt="" loading="lazy" className="article-card__image" />
                </div>
                <div className="article-card__body">
                    <h3 className="article-card__title">{title}</h3>
                    <div className="article-card__author">{author}</div>
                    <p className="article-card__short-content">{shortContent}</p>
                    <div className="article-card__date">{date}</div>
                </div>
            </Link>
        </div>
    )
}

export default ArticleCard