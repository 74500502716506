import { Link } from "react-router-dom"


const LatestArticleCard = ({ path, imageSrc, title, author }) => {
    return (
        <div className="latest-article-card shadow-sm">
            <Link to={path}>
                <div className="latest-article-card__header">
                    <img className="latest-article-card__image" src={imageSrc} alt={title} />
                </div>
                <div className="latest-article-card__body">
                    <h1 className="latest-article-card__title">{title}</h1>
                    <p className="latest-article-card__author">{author}</p>
                </div>
            </Link>
        </div>
    )
}

export default LatestArticleCard