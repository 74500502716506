import { useState } from 'react'
import OverlayNavigation from '../OverlayNavigation/OverlayNavigation'


const OverlayNavigationIcon = () => {
    const [overlayNavigationHeight, setOverlayNavigationHeight] = useState('0')

    const openOverlayNavigationOnClick = () => {
        if(overlayNavigationHeight === '0') {
            setOverlayNavigationHeight('100%')
        }
    }

    const closeOverlayNavigationOnClick = () => {
        if(overlayNavigationHeight !== '0') {
            setOverlayNavigationHeight('0')
        }
    }

    return (
        <>
            <i className="overlay-navigation-icon fas fa-bars" onClick={openOverlayNavigationOnClick}></i>
            <OverlayNavigation overlayNavigationHeight={overlayNavigationHeight} closeOverlayNavigationOnClick={closeOverlayNavigationOnClick} />
        </>
    )
}

export default OverlayNavigationIcon