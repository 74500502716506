

// export const NAVIGATION = [
//     {
//         categoryName: 'Slow Moda',
//         categoryPath: '/fashion',
//         subcategories: []
//     },
//     {
//         categoryName: 'Slow Dom',
//         categoryPath: '/home',
//         subcategories: [], 
//     },
//     {
//         categoryName: 'Slow Sztuka',
//         categoryPath: '/art',
//         subcategories: [],
//     },
//     {
//         categoryName: 'Magazyn',
//         categoryPath: '/magazine',
//         subcategories: [],
//     },
// ]

export const NAVIGATION = [
    {
        name: 'SHOP THE LOOK',
        path: undefined,
        subcategories: [
            {
                name: 'LESS IS MORE',
                path: '/produkty/less-is-more'
            },
            {
                name: 'MORE IS EVERYTHING',
                path: '/produkty/more-is-everything'
            },
            {
                name: 'STYLOWY DOM',
                path: '/produkty/stylowy-dom'
            },
            // {
            //     name: 'INSTA',
            //     path: 'https://www.instagram.com/slowfashionpolska/'
            // },
            // {
            //     name: 'BIŻU',
            //     path: '/produkty/bizu'
            // }
        ]
    },
    {
        name: 'MAGAZYN',
        path: '/magazine',
        subcategories: [], 
    },
    {
        name: 'INSPIRACJE',
        path: '/posty',
        subcategories: [],
    },
    {
        name: 'MARKI',
        path: undefined,
        subcategories: [
            {
                name: 'SLOW MODA',
                path: '/fashion'
            },
            {
                name: 'SLOW DOM',
                path: '/home'
            },
            {
                name: 'SLOW SZTUKA',
                path: '/art'
            },
        ],
    },
]