import { Link } from 'react-router-dom';
import { NAVIGATION } from '../../constants/navigation';

import NavigationItem from './NavigationItem';
import SocialMedia from '../SocialMedia/SocialMedia';


const Navigation = () => {
    return (
        <nav className="navigation container p-0 my-3 d-flex justify-content-between align-items-center">
            <ul className="navigation__list d-flex">
                {NAVIGATION.map((category, index) => {
                    return (
                        <NavigationItem 
                            key={index} 
                            className="navigation-item"
                            subcategory={category.subcategories}
                        >
                            <Link to={category.path} className="navigation-link">
                                {category.name}
                            </Link>
                        </NavigationItem>
                    ) 
                })}
                {/* <li className="navigation__list-item">
                    <Link to="/gift-guide" className="navigation__link d-flex align-items-center">
                        <IconContext.Provider value={{ style: { fontSize: '30px', padding: '0 5px' } }} >
                            <BsFillSuitHeartFill />   
                        </IconContext.Provider>
                        Walentynki
                    </Link>
                </li> */}
            </ul>
            <SocialMedia />
        </nav>
    );
}

export default Navigation;
