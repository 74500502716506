

const ArticleBody = ({ full_content }) => {
    return (
        <div className="article-body d-flex flex-column">
            <div dangerouslySetInnerHTML={{ __html: full_content }}></div>
        </div>
    )
}

export default ArticleBody