import { Link } from "react-router-dom"


const ArticleNavigation = ({ title, url, icon }) => {
    return (
        <div className="article-navigation">
            <Link to={url} className="article-navigation__link d-flex flex-column align-items-center">
                {icon}
                <div className="article-navigation__title">{title}</div>
            </Link>
        </div>
    )
}

export default ArticleNavigation