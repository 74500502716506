import { useParams } from "react-router-dom"
import { IconContext } from "react-icons/lib"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"

import Loader from "../../components/Loader/Loader"
import ErrorPage from "../../components/ErrorPage/ErrorPage"
import useFetchData from "../../hooks/useFetchData"
import Article from "./Article"
import ArticleHeader from "./ArticleHeader"
import ArticleBody from "./ArticleBody"
import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton"

import {
    BASE_URL,
    API_ENDPOINTS
} from '../../settings'
import ArticleNavigation from "./ArticleNavigation"


const trimText = (text, length) => {
    return text.split(' ').slice(0, length).join(' ') + ' ...'
}

const ArticleOverview = () => {
    const { id } = useParams()
    const { data: currentArticle, isLoading: loadingCurrentArticle, error: currentArticleError } = useFetchData(BASE_URL + API_ENDPOINTS.articles + `/${id}`)
    const { data: previousArticle, isLoading: loadingPreviousArticle, error: previousArticleError } = useFetchData(BASE_URL + API_ENDPOINTS.previousArticle + `/${id}`)
    const { data: nextArticle, isLoading: loadingNextArticle, error: nextArticleError } = useFetchData(BASE_URL + API_ENDPOINTS.nextArticle + `/${id}`)

    return (
      <section className="article-overview d-flex flex-column">
        {loadingCurrentArticle && <Loader />}
        {currentArticleError && <ErrorPage />}
        {currentArticle && (
          <Article
            header={
              <ArticleHeader
                title={currentArticle.title}
                author={currentArticle.author}
                date={currentArticle.date}
              />
            }
            body={<ArticleBody full_content={currentArticle.full_content} />}
            footer={
              <div className="article-footer container d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  {loadingPreviousArticle && <Loader />}
                  {previousArticleError && <ErrorPage />}
                  {previousArticle && (
                    <div className="article-footer__wrapper d-flex flex-row align-items-center">
                      <ArticleNavigation
                        title={trimText(previousArticle.title, 4)}
                        url={`/articles/${previousArticle.id}`}
                        icon={<BsArrowLeft className="article-footer__left-arrow" />}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column">
                  {loadingNextArticle && <Loader />}
                  {nextArticleError && <ErrorPage />}
                  {nextArticle && (
                    <div className="article-footer__wrapper d-flex flex-row align-items-center">
                      <ArticleNavigation
                        title={trimText(nextArticle.title, 4)}
                        url={`/articles/${nextArticle.id}`}
                        icon={<BsArrowRight className="article-footer__right-arrow" />}
                      />
                    </div>
                  )}
                </div>
              </div>
            }
          ></Article>
        )}
        <ScrollTopButton />
      </section>
    );
}

export default ArticleOverview