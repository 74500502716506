import { useState } from "react"

import DropdownItem from "../Dropdown/DropdownItem"
import Dropdown from "../Dropdown/Dropwdown"


const NavigationItem = ({ children, className, subcategory }) => {
    const [isActive, setIsActive] = useState(false)

    return (
        <li 
            className={`${className}`}
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
            onClick={() => setIsActive(false)}
        >
            {children}
            {subcategory && subcategory.length > 0 
                ?
                    <Dropdown isActive={isActive}>
                        {subcategory.map((subcategory, index) =>
                            <DropdownItem 
                                key={index}
                                path={subcategory.path} 
                                text={subcategory.name} 
                            />
                        )}
                    </Dropdown>
                : 
                    null
            }
        </li>
    )
}

export default NavigationItem