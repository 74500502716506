

const About = () => {
    return (
        <div className="container d-flex justify-content-center">
            <section className="about d-flex flex-column text-center">
                <h1 className="about__header">O nas</h1>
                <p className="about__paragraph">Czy wiesz, że dokładnie co sekundę, na wysypisko śmieci trafia ciężarówka pełna ubrań a bardziej od przemysłu modowego truje tylko branża paliwowa?</p>
                <p className="about__paragraph">A czy wiesz, że masz realny wpływ na zmianę tego stanu rzeczy?</p>
                <p className="about__paragraph">Tak, to prawda, a my jesteśmy tu po to, by pomóc Ci dokonywać właściwych wyborów zakupowych, uświadomić Ci, jak ważne jest zrównoważone podejście do mody.</p>
                <p className="about__paragraph">Chcemy, żebyś kupował/ kupowała w myśl zasady „mniej znaczy więcej”. Chcemy ułatwić Ci funkcjonowanie bez klimatycznej czkawki i planetarnych wyrzutów sumienia 😊</p>
                <p className="about__paragraph">Znajdziesz u nas selekcję najciekawszych, polskich marek slow fashion. Nie tylko otrzymasz dostęp do produktów najwyższej jakości ale wesprzesz rodzimych producentów i rękodzielników. Poznasz nowe, etyczne i niezwykle interesujące firmy ale także polskich artystów.</p>
                <p className="about__paragraph">Dołącz do nas i stań się odpowiedzialnym konsumentem.</p>
                <p className="about__paragraph">I pamiętaj- slogan „Planeta B nie istnieje” jest prawdziwy.</p>
                <p className="about__paragraph"><b>Slow Fashion Polska team.</b></p>
            </section>
        </div>
    );
}

export default About;