import { NAVIGATION } from '../../constants/navigation';
import useFixedNavigation from '../../hooks/useFixedNavigation';

import { Link } from 'react-router-dom';
import NavigationItem from '../Navigation/NavigationItem';
import SocialMedia from '../SocialMedia/SocialMedia';
import Logo from '../Logo/Logo';
import { IconContext } from 'react-icons';
import { BsFillSuitHeartFill } from 'react-icons/bs'


const FixedNavigation = () => {
    const FIXED_NAVIGATION_SCROLL_BREAKPOINT = 686
    const fixedNavigation = useFixedNavigation(FIXED_NAVIGATION_SCROLL_BREAKPOINT)

    return (
        <nav className="fixed-navigation container shadow-sm" style={{display: fixedNavigation ? 'block' : 'none'}}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="fixed-navigation__logo">
                    <Logo type="secondary" />
                </div>
                <div className="fixed-navigation__navigation">
                    <ul className="fixed-navigation__list d-flex align-items-center">
                        {NAVIGATION.map((category, idx) =>
                            <NavigationItem 
                                key={idx}
                                className="fixed-navigation__list-item" 
                                subcategory={category.subcategories}
                            >
                                    <Link to={category.path} className="fixed-navigation__link">
                                        {category.name}
                                    </Link>
                            </NavigationItem>
                        )}
                        {/* <li className="fixed-navigation__list-item">
                            <Link to='/gift-guide' className="fixed-navigation__link d-flex align-items-center">
                                <IconContext.Provider value={{ style: { fontSize: '25px', padding: '0 5px' } }} >
                                    <BsFillSuitHeartFill />   
                                </IconContext.Provider>
                                Walentynki
                            </Link>
                        </li> */}
                    </ul>
                </div>
                <div className="fixed-navigation__social-media">
                    <SocialMedia />
                </div>
            </div>
        </nav>
    );
}

export default FixedNavigation;