import Content from '../../components/Content/Content';
import useFetchData from '../../hooks/useFetchData';
import ScrollTopButton from '../../components/ScrollTopButton/ScrollTopButton';
import { BASE_URL , API_ENDPOINTS } from '../../settings';


const Fashion = () => {
    const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.fashion)

    return (
        <>
            <Content data={data} isLoading={isLoading} error={error} />
            <ScrollTopButton />
        </>
    )
}

export default Fashion