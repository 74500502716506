import { Link } from 'react-router-dom';
import SocialMedia from '../SocialMedia/SocialMedia';
import Logo from '../Logo/Logo';


const Footer = () => {

    const handleClick = () => {
        window.scrollTo(0, 0);
    }

    return (
        <footer className="footer">
            <div className="footer__navigation d-flex flex-row justify-content-center">
                <Link to='/about' className="footer__navigation-link">O nas</Link>
                <Link to='/terms' className="footer__navigation-link">Regulamin</Link>
            </div>
            <div className="footer__contact d-flex justify-content-center">
                <Link className="footer__contact-email d-flex align-items-center" to={{ pathname: "mailto:slowfashionpl@gmail.com" }} target="_blank">
                    <i className="footer__contact-icon far fa-envelope"></i>
                    <b>Kontakt:</b>&nbsp;slowfashionpl@gmail.com
                </Link>
            </div>
            <div className="footer__social-media d-flex justify-content-center">
                <SocialMedia />
            </div>
            <div className="footer__logo d-flex justify-content-center">
                <Link to="/" onClick={handleClick} className="d-flex flex-row align-items-center justify-content-center">
                    <Logo type="primary" />
                </Link>
            </div>
            <div className="footer__copyright d-flex justify-content-center">
                &copy; 2023 Slow Fashion. Polska
            </div>
            <div className="footer__author d-flex flex-row justify-content-center align-items-center">
                <p className="footer__author-name">Realizacja: Marcin Bugiel</p>
                <Link className="footer__author-link" to={{ pathname: "mailto:mbugiel656@gmail.com"}} target="_blank">
                    <i className="footer__author-icon far fa-envelope"></i>
                </Link>
                <Link className="footer__author-link" to={{ pathname: "https://www.linkedin.com/in/mbugiel656/"}} target="_blank">
                    <i className="footer__author-icon fab fa-linkedin"></i>
                </Link>
                <Link className="footer__author-link" to={{ pathname: "https://github.com/bugiss656"}} target="_blank">
                    <i className="footer__author-icon fab fa-github"></i>
                </Link>
            </div>
        </footer>
    )
}

export default Footer;