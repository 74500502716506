import About from "../pages/About/About";
import Art from "../pages/Art/Art";
import ArticleOverview from "../pages/ArticleOverview/ArticleOverview";
import BrandDetails from "../pages/BrandDetails/BrandDetails";
import Fashion from "../pages/Fashion/Fashion";
import Home from "../pages/Home/Home";
import Homepage from "../pages/Homepage/Homepage";
import Magazine from "../pages/Magazine/Magazine";
import Products from "../pages/Products/Products";
import Terms from "../pages/Terms/Terms";
import GiftGuide from "../pages/GiftGuide/GiftGuide"
import Posts from "../pages/Posts/Posts";
import PostOverview from "../pages/PostOverview/PostOverview";


export const ROUTES = [
    {
        name: 'homepage',
        path: '/',
        exact: true,
        component: Homepage
    },
    {
        name: 'fashion',
        path: '/fashion',
        exact: false,
        component: Fashion
    },
    {
        name: 'home',
        path: '/home',
        exact: false,
        component: Home
    },
    {
        name: 'art',
        path: '/art',
        exact: false,
        component: Art
    },
    {
        name: 'posts',
        path: '/posty',
        exact: true,
        component: Posts
    },
    {
        name: 'postOverview',
        path: '/posty/:slug',
        exact: false,
        component: PostOverview
    },
    {
        name: 'produkty',
        path: '/produkty/:category',
        exact: false,
        component: Products
    },
    {
        name: 'magazine',
        path: '/magazine',
        exact: false,
        component: Magazine
    },
    {
        name: 'about',
        path: '/about',
        exact: false,
        component: About
    },
    {
        name: 'terms',
        path: '/terms',
        exact: false,
        component: Terms
    },
    {
        name: 'brandDetails',
        path: '/brands/:name',
        exact: false,
        component: BrandDetails
    },
    {
        name: 'articleOverview',
        path: '/articles/:id',
        exact: false,
        component: ArticleOverview
    },
    // {
    //     name: 'giftGuide',
    //     path: '/gift-guide',
    //     exact: false,
    //     component: GiftGuide
    // },
    // {
    //     name: 'forHer',
    //     path: '/for-her',
    //     exact: false,
    //     component: GiftForHer
    // },
    // {
    //     name: 'forHim',
    //     path: '/for-him',
    //     exact: false,
    //     component: GiftForHim
    // },
    // {
    //     name: 'forKid',
    //     path: '/for-kid',
    //     exact: false,
    //     component: GiftForKid
    // }
]