import { useState, useEffect } from "react";

const ScrollTopButton = () => {
    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleDisplayScrollButton = () => {
        if(!showScrollButton && window.pageYOffset > 400) {
            setShowScrollButton(true);
        } else if(showScrollButton && window.pageYOffset <= 400) {
            setShowScrollButton(false);
        }
    };

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleDisplayScrollButton);

        return () => {
            window.removeEventListener('scroll', handleDisplayScrollButton);
        }
    });

    return (
        <div onClick={handleScrollTop} className="scroll-top" style={{display: showScrollButton ? 'block' : 'none'}}>
            <i className="scroll-top__arrow fas fa-chevron-up"></i>
        </div>
    );
}

export default ScrollTopButton;