import { Link } from "react-router-dom"


const PostCard = ({ path, imageSrc, alt, title }) => {
    return (
        <div className="post">
            <Link to={path} className="post__link d-flex flex-column align-items-center">
                <img className="post__image" src={imageSrc} alt={alt} />
                <div className="post__title">{title}</div>
            </Link>
        </div>
    )
}

export default PostCard