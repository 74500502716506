import {
    BASE_URL,
    API_ENDPOINTS
} from '../../settings'
import useFetchData from '../../hooks/useFetchData'

import PostCard from "../../components/PostCard/PostCard"
import Loader from '../../components/Loader/Loader'
import ScrollTopButton from '../../components/ScrollTopButton/ScrollTopButton'


const Posts = () => {
    const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.posts)

    return (
        <div className="posts container p-0 my-5">
            <h1 className="posts-heading text-center">INSPIRACJE</h1>

            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center my-5">
                {isLoading && <Loader />}
                {error && <div>{error}</div>}
                {data &&
                    data.map((post, index) =>
                        <PostCard
                            key={index}
                            path={`/posty/${post.slug}`}
                            imageSrc={post.main_image}
                            alt={post.alt}
                            title={post.title}
                        />
                    )
                }
            </div>
            <ScrollTopButton />
        </div>
    )
}

export default Posts