import { useState, useEffect } from "react"


const useOverlayNavigationIcon = () => {
    const [overlayNavigation, setOverlayNavigation] = useState(null)

    const OVERLAY_NAVIGATION_WIDTH_BREAKPOINT = 1024

    const handleOverlayNavigation = () => {
        if(window.innerWidth <= OVERLAY_NAVIGATION_WIDTH_BREAKPOINT) {
            setOverlayNavigation(true)
        } else if(window.innerWidth > OVERLAY_NAVIGATION_WIDTH_BREAKPOINT) {
            setOverlayNavigation(false)
        }
    }

    useEffect(() => { 
        handleOverlayNavigation()
    })

    useEffect(() => {
        window.addEventListener('resize', () => {
            handleOverlayNavigation()
        })
    }, [overlayNavigation])

    return overlayNavigation
}

export default useOverlayNavigationIcon