import ArticleCard from "../ArticleCard/ArticleCard"
import ErrorPage from "../ErrorPage/ErrorPage"
import Loader from "../Loader/Loader"

import useFetchData from "../../hooks/useFetchData"
import { 
    BASE_URL,
    API_ENDPOINTS 
} from "../../settings"


const ArticlesList = () => {
    const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.articles)
    
    return (
        <div className="articles-container d-flex flex-row flex-wrap justify-content-center">
            {isLoading && <Loader />}
            {error && <ErrorPage statusCode={error.status} statusText={error.statusText} />}
            {data && 
                data.map(article => 
                    <ArticleCard 
                        key={article.title}
                        title={article.title}
                        author={article.author}
                        date={article.date}
                        url={`/articles/${article.id}`}
                        imageUrl={article.image}
                    />
                )
            }
        </div>
    )
}

export default ArticlesList