import { Link } from "react-router-dom"


const DropdownItem = ({ path, text, target }) => {
    return (
        <li className="dropdown__item">
            <Link to={path} className="dropdown__link" target={target}>{text}</Link>
        </li>
    )
}

export default DropdownItem