

const Collapse = ({ children, isExpanded }) => {
    return (
        <div className={`collapse-list ${isExpanded ? 'collapse-list--expanded' : 'collapse-list--collapsed'}`}>
            {children}
        </div>
    )
}

export default Collapse