import { Link } from "react-router-dom"


const CategoryCard = ({ imgSrc, url, title, target }) => {
    return (
        <div className="category-card">
            <Link to={url} className="category-card__link" target={target}>
                <div className="category-card__hover"></div>
                <img src={imgSrc} alt="" className="category-card__image" />
                {/* <div className="category-card__title">{title}</div> */}
            </Link>
        </div>
    )
}

export default CategoryCard