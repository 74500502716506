import { useParams } from "react-router-dom"
import { 
    API_ENDPOINTS,
    BASE_URL
} from '../../settings'
import useFetchData from "../../hooks/useFetchData"

import Loader from '../../components/Loader/Loader'
import Product from "./Product"
import Masonry from "react-masonry-css"
import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton"


const Products = () => {
    const { category } = useParams()
    const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.products + `/${category}`)

    const formatCategoryName = (category) => {
        return category
            .replaceAll("-", " ")
            .split(" ")
            .map(word => word.toUpperCase())
            .join(" ")
    }

    return (
        <div className="products container p-0 d-flex flex-column justify-content-center align-items-center">
            {isLoading && <Loader />}
            {error && <div>{error}</div>}
            {data && (
                <>
                    <div className="products__header d-flex flex-column align-items-center">
                        <h1 className="products__title">{formatCategoryName(category)}</h1>
                        <div className="products__separator"></div>
                    </div>
                    <div className="products__text" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                    <Masonry
                        breakpointCols={4}
                        className="products__grid"
                        columnClassName="products__grid-column"
                    >
                        {data.products.map((product, index) =>
                            <Product 
                                key={index} 
                                path={product.link}
                                text={product.brand}
                                imageSrc={product.image}
                                imageAlt={product.alt}
                            />
                        )}
                    </Masonry>
                </>
            )}
            <ScrollTopButton />
        </div>
    )
}

export default Products