import { 
    API_ENDPOINTS,
    BASE_URL,
    STATIC_URL 
} from '../../settings'
import useFetchData from '../../hooks/useFetchData'

import { Link } from 'react-router-dom'
import HomepageCard from './HomepageCard'
import Loader from '../../components/Loader/Loader'
import ScrollTopButton from '../../components/ScrollTopButton/ScrollTopButton'
import LatestArticleCard from '../../components/LatestArticleCard/LatestArticleCard'
import CategoryCard from '../../components/CategoryCard/CategoryCard'
import PostCardExtended from '../../components/PostCardExtended/PostCardExtended'


const Homepage = () => {
    const { data: latestArticles, isLoading: loadingLatestArticles, error: latestArticlesError } = useFetchData(BASE_URL + API_ENDPOINTS.latestArticles)
    const { data: posts, isLoading: loadingPosts, error: postsError } = useFetchData(BASE_URL + API_ENDPOINTS.posts)

    return (
        <div className="container p-0">
            <section className="homepage">
                <div className="d-flex flex-row justify-content-center my-5">
                    <CategoryCard 
                        imgSrc={`${STATIC_URL}/less-is-more.png`}
                        url="produkty/less-is-more"
                        title="LESS IS MORE"
                    />
                    <CategoryCard 
                        imgSrc={`${STATIC_URL}/more-is-everything.png`}
                        url="produkty/more-is-everything"
                        title="MORE IS EVERYTHING"
                    />
                    <CategoryCard 
                        imgSrc={`${STATIC_URL}/stylowy-dom.png`}
                        url="produkty/stylowy-dom"
                        title="STYLOWY DOM"
                    />
                    {/* <CategoryCard 
                        imgSrc={`${STATIC_URL}/bizu.jpg`}
                        url="produkty/bizu"
                        title="BIŻU"
                    /> */}
                    <CategoryCard 
                        imgSrc={`${STATIC_URL}/social-media.png`}
                        url={{ pathname: 'https://www.instagram.com/slowfashionpolska/' }}
                        title="SOCIAL MEDIA"
                        target="_blank"
                    />
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center p-0">
                    {loadingPosts && <Loader />}
                    {postsError && <div>{latestArticlesError}</div>}
                    {posts &&
                        posts.map((post, index) =>
                            <PostCardExtended 
                                key={index}
                                path={`/posty/${post.slug}`}
                                imgSrc={post.main_image}
                                imgAlt={post.alt}
                                title={post.title}
                                products={post.products}
                            />
                        )
                    }
                </div>

                <div className="latest-articles">
                    <div className="d-flex flex-column">
                        <div className="wrapper d-flex flex-row justify-content-center">
                            {loadingLatestArticles && <Loader />}
                            {latestArticlesError && <div>{latestArticlesError}</div>}
                            {latestArticles &&
                                latestArticles.map((article, index) => 
                                    <LatestArticleCard
                                        key={index}
                                        path={`/articles/${article.id}`}
                                        imageSrc={article.image}
                                        title={article.title}
                                        author={article.author}
                                    />
                                )
                            }
                        </div>
                        <div className="wrapper d-flex flex-row justify-content-center m-3">
                            <Link to="/magazine" className="latest-articles-button">
                                PRZEJDŹ DO MAGAZYNU
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="homepage__cards-container d-flex flex-row justify-content-around">
                    <Link to="/fashion">
                        <HomepageCard 
                            imageSrc={`${STATIC_URL}/fashion.jpg`} 
                            title="Slow moda" 
                            copyright="Kryszy" 
                        />
                    </Link>
                    <Link to="/home">
                        <HomepageCard 
                            imageSrc={`${STATIC_URL}/home.jpg`} 
                            title="Slow dom" 
                            copyright="Kurz" 
                        />
                    </Link>
                    <Link to="/art">
                        <HomepageCard 
                            imageSrc={`${STATIC_URL}/art.jpg`} 
                            title="Slow sztuka" 
                            copyright="Panna Niebieska" 
                        />
                    </Link>
                </div>
            </section>
            <ScrollTopButton />
        </div>
    )
}

export default Homepage;