import React from "react";

import Carousel from "../Carousel/Carousel";
import BrandContactItem from "./BrandContactItem";

import { BsGlobe } from 'react-icons/bs'
import { AiOutlineInstagram } from 'react-icons/ai'
import { AiOutlineFacebook } from 'react-icons/ai'


const Brand = ({ data }) => {

    const splitedDescription = data.full_description.split('\n').map(text => {
        return text.replaceAll('\\n', '')
    })

    return (
        <div className="brand d-flex flex-column">
            <div className="brand__item d-flex flex-row">
                <div className="brand__info">
                    <h1 className="brand__header header-label label-black">{data.display_name}</h1>
                    <section className="brand__description">
                        {
                            splitedDescription.map((paragraph, id) => {
                                return (
                                    <React.Fragment key={id}>
                                        <p>{paragraph}</p>
                                    </React.Fragment> 
                                )
                            }) 
                        }
                    </section>
                </div>
                <div className="brand__carousel d-flex justify-content-center">
                    <Carousel data={data} />
                </div>
            </div>
            <div className="brand__item">
                <h1 className="brand__header header-label label-black">Media</h1>
                <div className="d-flex flex-column">
                    {data.website
                        ?
                            <BrandContactItem
                                url={data.website}
                                icon={<BsGlobe />}
                                text={data.website}
                            />
                        : null
                    }
                    {data.instagram
                        ?
                            <BrandContactItem
                                url={data.instagram}
                                icon={<AiOutlineInstagram />}
                                text={data.website}
                            />
                        : null
                    }
                    {data.facebook
                        ?
                            <BrandContactItem
                                url={data.facebook}
                                icon={<AiOutlineFacebook />}
                                text={data.facebook}
                            />
                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default Brand;