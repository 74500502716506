

export const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD_URL : process.env.REACT_APP_API_DEV_URL
export const STATIC_URL = process.env.NODE_ENV === 'production' ? '/static' : `${process.env.PUBLIC_URL}/assets`
// export const MEDIA_URL = '/media'

export const API_ENDPOINTS = {
    fashion: '/api/fashion',
    home: '/api/home',
    art: '/api/art',
    brand: '/api/brands',

    banners: '/banners',

    articles: '/magazine/articles',
    previousArticle: '/magazine/articles/previous',
    nextArticle: '/magazine/articles/next',
    latestArticles: '/magazine/articles/latest',

    posts: '/posts',
    products: '/products',

    giftGuide: '/giftguide',
    // forHer: '/her',
    // forHim: '/him',
    // forKid: '/kid',
    // valentines: '/valentines'
    christmas: '/christmas',
}