import { Link } from "react-router-dom"
import ProductsWidget from "../ProductsWidget/ProductsWidget"


const PostCardExtended = ({ path, imgSrc, imgAlt, title, products }) => {
    return (
        <div className="post-card-extended d-flex flex-row justify-content-center align-items-center">
            <div className="post-card-extended__thumbnail d-flex justify-content-center align-items-center">
                <Link to={path}>
                    <img src={imgSrc} alt={imgAlt} />
                </Link>
            </div>
            <div className="post-card-extended__info d-flex flex-column justify-content-around align-items-center">
                <h1 className="post-card-extended__info-title">{title.toUpperCase()}</h1>
                <div className="post-card-extended__info-products d-flex flex-row">
                    <ProductsWidget items={products} itemsPerPage={3} />
                </div>
                <Link to={path}>
                    <div className="post-card-extended__button">CZYTAJ</div>
                </Link>
            </div>
        </div>
    )
}

export default PostCardExtended