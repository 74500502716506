import { Link } from "react-router-dom";


const SocialMedia = () => {
    return (
        <div className="social-media d-flex align-items-center justify-content-around text-end">
            <div className="social-media__item d-flex align-items-center">
                <Link to={{ pathname: 'https://www.facebook.com/slowfashionpolska'}} target="_blank">
                    <i className="social-media__icon fab fa-facebook-square"></i>
                </Link> 
            </div>
            <div className="social-media__item d-flex align-items-center">
                <Link to={{ pathname: 'https://www.instagram.com/slowfashionpolska/'}} target="_blank">
                    <i className="social-media__icon fab fa-instagram-square"></i>
                </Link>
            </div>
        </div>
    );
}

export default SocialMedia;