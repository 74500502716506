import { useState, useEffect } from "react";


const useFixedNavigation = (breakpoint) => {
    const [fixedNavigation, setFixedNavigation] = useState(false);

    const handleFixedNavigation = () => {
            if(window.pageYOffset > breakpoint) {
                setFixedNavigation(true);
            } else if(window.pageYOffset <= breakpoint) {
                setFixedNavigation(false);
            }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            handleFixedNavigation()
        })
    });

    return fixedNavigation;
}

export default useFixedNavigation;