import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl'


const handleSplitProducts = (items, itemsPerPage) => {
    const pagesCount = Math.ceil(items.length / itemsPerPage)
    const result = []

    for (let i = 0; i <= pagesCount - 1; i++) {
        const start = i * itemsPerPage
        const end = start + itemsPerPage

        result.push(items.slice(start, end))
    }

    return result
}


const ProductsWidget = ({ items, itemsPerPage }) => {

    const [currentItems, setCurrentItems] = useState(handleSplitProducts(items, itemsPerPage))
    const [currentPage, setCurrentPage] = useState(0)
    const [showArrows, setShowArrows] = useState(true)

    const previousPage = () => {
        if (currentPage <= 0) {
            setCurrentPage(currentItems.length - 1)
        } else {
            setCurrentPage(prevPage => prevPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage >= currentItems.length - 1) {
            setCurrentPage(0)
        } else {
            setCurrentPage(prevPage => prevPage + 1)
        }
    }

    useEffect(() => {
        if (currentItems.length < 2) {
            setShowArrows(false)
        }
    }, [currentItems.length])

    return (
        <div className="products-widget d-flex flex-row justify-content-center align-items-center">
            <SlArrowLeft className="products-widget__arrow" onClick={() => previousPage()} style={{ display: showArrows === true ? 'block' : 'none' }} />
            {currentItems[currentPage].map((item, idx) => 
                <div key={idx} className="products-widget__product position-relative">
                    <Link to={{ pathname: item.link }} target="_blank">
                        <img className="products-widget__product-image" src={item.thumbnail} alt="" />
                        <div className="products-widget__title">{item.brand}</div>
                        <div className="products-widget__product-hover"></div>
                    </Link>
                </div>
            )}
            <SlArrowRight className="products-widget__arrow" onClick={() => nextPage()} style={{ display: showArrows === true ? 'block' : 'none' }}/>
        </div>
    )
}

export default ProductsWidget