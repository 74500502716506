import { useParams } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import { BASE_URL, API_ENDPOINTS } from "../../settings"

import Loader from "../../components/Loader/Loader";
import Brand from "../../components/Brand/Brand";
import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton";


const BrandDetails = () => {
    const { name } = useParams();
    const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.brand + `/${name}`);

    return (
        <section className="container justify-content-center">
            <div className="brand-details">
                {isLoading && <Loader />}
                {error && <span>{error}</span>}
                {data && <Brand data={data} />}
            </div>
            <ScrollTopButton />
        </section>
    );
}

export default BrandDetails;