import { Link } from "react-router-dom"


const CollapseItem = ({ path, text, onClick }) => {
    return (
        <div className="collapse-list__item" onClick={onClick}>
            <Link to={path}>{text}</Link>
        </div>
    )
}

export default CollapseItem