import { Link } from "react-router-dom"


const BrandCard = ({ brandCardUrl, brandCardImageSrc, brandCardTitle }) => {
    return (
        <Link to={brandCardUrl} className="brand__card d-flex justify-content-center">
            <img src={brandCardImageSrc} alt="" className="brand__card-image" loading="lazy" />
            <div className="brand__image-hover"></div>
            <p className="brand__card-title">{brandCardTitle}</p>
        </Link>
    )
}

export default BrandCard