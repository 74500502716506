import { useState } from "react"
import { Link } from "react-router-dom"
import { IconContext } from "react-icons"
import { SlArrowUp, SlArrowDown } from "react-icons/sl"

import Collapse from "../Collapse/Collapse"
import CollapseItem from "../Collapse/CollapseItem"


const OverlayNavigationListItem = ({ href, itemLinkText, icon, subcategories, closeOverlayNavigationOnClick, target }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <>
            <li 
                className="overlay-navigation__list-item d-flex flex-row align-items-center justify-content-center"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <Link to={href} className="overlay-navigation__link d-flex align-items-center" onClick={href ? closeOverlayNavigationOnClick : null} target={target}>
                    {icon}
                    {itemLinkText}
                    {(subcategories && subcategories.length > 0) &&
                        <IconContext.Provider
                            value={{
                                style: {
                                    margin: '0 5px',
                                    fontSize: '18px',
                                    transform: `${isExpanded ? 'rotate(180deg)' : 'rotate(0)'}`,
                                    transition: 'transform 0.4s'
                                }
                            }}
                        >
                            <SlArrowUp />
                        </IconContext.Provider>
                    }
                </Link>
            </li>
            {subcategories && subcategories.length > 0 
                ?
                    <Collapse isExpanded={isExpanded}>
                        {subcategories.map((subcategory, index) =>
                            <CollapseItem 
                                key={index}
                                path={subcategory.path} 
                                text={subcategory.name} 
                                onClick={closeOverlayNavigationOnClick} 
                            />
                        )}
                    </Collapse>
                :
                    null
            }
        </>
    )
}

export default OverlayNavigationListItem