import useMobileFixedNavigation from "../../hooks/useMobileFixedNavigation"
import Logo from "../Logo/Logo"
import OverlayNavigationIcon from "../OverlayNavigationIcon/OverlayNavigationIcon"
import SocialMedia from "../SocialMedia/SocialMedia"


const MobileFixedNavigation = () => {
    const SCREEN_WIDTH_BREAKPOINT = 576
    const SCREEN_HEIGHT_BREAKPOINT = 259

    const mobileFixedNavigation = useMobileFixedNavigation(SCREEN_WIDTH_BREAKPOINT, SCREEN_HEIGHT_BREAKPOINT)

    return (
        <div className="mobile-fixed-navigation container shadow-sm" style={{ display: mobileFixedNavigation ? 'block' : 'none' }}>
            <div className="mobile-fixed-navigation__wrapper d-flex flex-row justify-content-between align-items-center">
                <div className="mobile-fixed-navigation__item">
                    <OverlayNavigationIcon />
                </div>
                <div className="mobile-fixed-navigation__item">
                    <Logo type="secondary" />
                </div>
                <div className="mobile-fixed-navigation__item">
                    <SocialMedia />
                </div>
            </div>
        </div>
    )
}

export default MobileFixedNavigation