import { useState, useEffect } from "react"


const useMobileFixedNavigation = (widthBreakpoint, heightBreakpoint) => {
    const [mobileFixedNavigation, setMobileFixedNavigation] = useState(false)

    const handleMobileFixedNavigation = () => {
        if (window.innerWidth <= widthBreakpoint) {
            if (window.pageYOffset > heightBreakpoint) {
                setMobileFixedNavigation(true)
            } else if (window.pageYOffset <= heightBreakpoint) {
                setMobileFixedNavigation(false)
            }
        }
    }

    useEffect(() => {
        handleMobileFixedNavigation()
    })

    useEffect(() => {
        window.addEventListener('scroll', () => {
            handleMobileFixedNavigation()
        })
    })

    return mobileFixedNavigation
}

export default useMobileFixedNavigation