

const ArticleHeader = ({ category, title, author, date }) => {
    return (
        <>
            <div className="article-header container d-flex flex-column justify-content-start mb-5">
                <hr />
                <h1 className="article-header__title">{title}</h1>
                <div className="article-details d-flex flex-row align-items-center">
                    <div className="article-details__author fst-italic">{author}</div>
                    <div className="fst-italic mx-2">|</div>
                    <div className="article-details__date fst-italic">{date}</div>
                </div>
                <hr />
            </div>
        </>
    )
}

export default ArticleHeader