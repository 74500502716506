import { Link } from "react-router-dom";


const Product = ({ path, text, imageSrc, imageAlt }) => {
    return (
        <div className="products__product position-relative">
            <Link to={{ pathname: path }} target='_blank'>
                <div className="products__image-hover"></div>
                <div className="products__image-text">
                    {text}
                    
                </div>
                <div className="products__text-underline"></div>
                <img
                    className="products__image"
                    src={imageSrc}
                    alt={imageAlt}
                    loading="lazy"
                />
            </Link>
      </div>
    )
}

export default Product