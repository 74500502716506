import Content from '../../components/Content/Content';
import useFetchData from '../../hooks/useFetchData';
import ScrollTopButton from '../../components/ScrollTopButton/ScrollTopButton';
import { BASE_URL, API_ENDPOINTS } from '../../settings';


const Art = () => {
    // const { data, isLoading, error } = useFetchData('/api/art');
    const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.art)

    return (
        <>
            <Content data={data} isLoading={isLoading} error={error} />
            <ScrollTopButton />
        </>
    )
}

export default Art