import { useState, useEffect } from "react"
import axios from "axios";


const useFetchData = (url) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const controller = new AbortController()

        axios.get(url, {
            signal: controller.signal,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setData(response.data);
                setIsLoading(false);
                setError(null);
            })
            .catch(error => {
                setIsLoading(false);
                //setError({ status: error.response.status, statusText: error.response.statusText });
                setError(error.message);
            })
        
            return () => {
                controller.abort()
            }
    }, [url]);
    

    return { data, isLoading, error };
}

export default useFetchData;