import './scss/App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Navigation from './components/Navigation/Navigation';
import FixedNavigation from './components/FixedNavigation/FixedNavigation';
import MobileFixedNavigation from './components/MobileFixedNavigation/MobileFixedNavigation';
import Logo from './components/Logo/Logo';

import Footer from './components/Footer/Footer';
import CookieConsent from 'react-cookie-consent';

import { ROUTES } from './constants/routes';

import { 
  BrowserRouter as Router, 
  Switch, 
  Route, 
  Link
} from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import useOverlayNavigationIcon from './hooks/useOverlayNavigationIcon';
import { useEffect } from 'react';
import { API_ENDPOINTS, BASE_URL } from './settings';
import Slider from './components/Slider/Slider';
import OverlayNavigationIcon from './components/OverlayNavigationIcon/OverlayNavigationIcon';
import SocialMedia from './components/SocialMedia/SocialMedia';
import Loader from './components/Loader/Loader';
import useFetchData from './hooks/useFetchData';


const App = () => {
  const overlayNavigationIcon = useOverlayNavigationIcon()
  const { data, isLoading, error } = useFetchData(BASE_URL + API_ENDPOINTS.banners)

  useEffect(() => {
    console.log(BASE_URL)
  }, [])

  return (
    <Router>
      <div className="container p-0 d-flex justify-content-center">
        {isLoading && <Loader />}
        {error && <div>{error}</div>}
        {data &&
          <Slider
            items={data}
            autoPlay={true}
            delay={6000}
            showArrows={true}
            showIndicators={true}
          />
        }
      </div>
      {overlayNavigationIcon 
        ?
          <div className="container p-0 d-flex flex-row justify-content-between align-items-center my-3">
            <OverlayNavigationIcon />
            <SocialMedia />
          </div>
        : 
          <Navigation />
      }
      <div className="logo-section d-flex flex-row justify-content-center">
        <Link to="/">
          <Logo type="primary" />
        </Link>
      </div>
      <FixedNavigation />
      <MobileFixedNavigation />
      <Switch>
        {ROUTES.map((route) => (
          <Route
            key={route.name}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
      <ScrollToTop />
      <Footer />
      <CookieConsent
        buttonText="Akceptuję"
        style={{
          backgroundColor: "#ffffff",
          fontSize: "14px",
          color: "#000000",
          border: "2px solid #000000",
        }}
        buttonStyle={{
          backgroundColor: "#ffffff",
          fontSize: "12px",
          width: "100px",
          height: "40px",
          border: "2px solid #000000",
        }}
      >
        Serwis korzysta z plików cookies w celu prawidłowego świadczenia usług,
        jak również w celach statystycznych oraz reklamowych.{" "}
        <Link to="terms">
          <b>Regulamin</b>
        </Link>
      </CookieConsent>
    </Router>
  );
}

export default App;
