import { STATIC_URL } from "../../settings"


const Logo = ({ type }) => {
    return (
        <>
            {type === 'primary'
                ?
                    <img className="logo-primary" src={`${STATIC_URL}/logo-primary.svg`} alt="Slow Fashion Polska" />
                :
                    <img className="logo-secondary" src={`${STATIC_URL}/logo-secondary.svg`} alt="Slow Fashion Polska" />
            }
        </>
    )
}

export default Logo