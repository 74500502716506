import { 
    useState, 
    useEffect, 
    useCallback 
} from 'react'

import { IconContext } from 'react-icons'
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useSwipeable } from 'react-swipeable'

import './Slider.scss'


const Slider = ({ items, autoPlay, delay, showIndicators }) => {
    const [currentItem, setCurrentItem] = useState(1)

    const nextItem = useCallback((items) => {
        if (currentItem >= items.length) {
            setCurrentItem(1)
        } else {
            setCurrentItem((prevItem) => prevItem + 1)
        }
    }, [currentItem])

    const prevItem = (items) => {
        if (currentItem <= 1) {
            setCurrentItem(items.length)
        } else {
            setCurrentItem((prevItem) => prevItem - 1)
        }
    }

    const renderItem = (item) => {
        let items = document.querySelectorAll('.slider__slide')

        for (let i of items) {
            i.style.display = 'none'
        }

        items[item - 1].style.display = 'block'
    }

    const renderIndicators = (indicator) => {
        let indicators = document.querySelectorAll('.indicator')

        for (let i of indicators) {
            i.classList.remove('active')
        }

        indicators[indicator - 1].classList.add('active')
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => nextItem(items),
        onSwipedRight: () => prevItem(items)
    })

    useEffect(() => {
        renderItem(currentItem)
        if (showIndicators) renderIndicators(currentItem)
    }, [currentItem, showIndicators])

    useEffect(() => {
        if (autoPlay) {
            const interval = setInterval(() => {
                nextItem(items)
            }, delay)
    
            return () => clearInterval(interval)
        }
    }, [autoPlay, currentItem, delay, items, nextItem])

    return (
        <div className="slider">
            <div className="slider__inner" {...swipeHandlers}>
                {items.map((item, idx) =>
                    <a className="slider__slide" key={idx} href={item.link}>
                        <img
                            src={item.image}
                            alt={item.title}
                            className="slider__image"
                        />
                        <div className="slider__content d-flex flex-column align-items-center">
                            <h1 className="slider__title">{item.title.toUpperCase()}</h1>
                            <div className="slider__separator"></div>
                            <p className="slider__text">ZOBACZ WIĘCEJ</p>
                        </div>
                    </a>
                )}
            </div>
            <div className="indicators">
                {items.map((_, idx) =>
                    <div
                        key={idx}
                        className="indicator"
                        onClick={() => setCurrentItem(idx + 1)}
                    >
                    </div>
                )}
            </div>
            {/* {isHovered
                ?
                <IconContext.Provider
                    value={{ color: '#ffffff', size: 50 }}
                >
                    <div className="prev" onClick={() => prevItem(items)}>
                        <MdOutlineArrowBackIos />
                    </div>
                    <div className="next" onClick={() => nextItem(items)}>
                        <MdOutlineArrowForwardIos />
                    </div>
                </IconContext.Provider>
                : null
            } */}
            
        </div>
    )
}

export default Slider