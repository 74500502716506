import BrandCard from "../BrandCard/BrandCard";
import ErrorPage from "../ErrorPage/ErrorPage";
import Loader from "../Loader/Loader";


const Content = ({ data, isLoading, error }) => {
    return (
        <section className="container justify-content-center">
            <div className="content">
                {isLoading && <Loader />}
                {error && <ErrorPage statusCode={error.status} statusText={error.statusText} />}
                {data && (
                    <div className="brand__cards d-flex flex-wrap flex-row justify-content-center align-items-center">
                        {data.map(brand =>
                            <BrandCard
                                key={brand.name}
                                brandCardUrl={`/brands/${brand.name}`}
                                // brandCardImageSrc={`/static/${brand.main_img_name}/${brand.main_img_name}-0.jpg`}
                                brandCardImageSrc={brand.brand_image}
                                brandCardTitle={brand.display_name}
                            />
                        )}
                    </div>
                )}
            </div>
        </section>
    );
}

export default Content;