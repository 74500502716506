

const Article = ({ header, body, footer }) => {
    return (
        <>
            <div className="d-flex flex-column">
                {header}
            </div>
            <div className="d-flex flex-column">
                {body}
            </div>
            <div className="d-flex flex-row justify-content-between my-5">
                {footer}
            </div>
        </>
    )
}

export default Article