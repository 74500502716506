

const Dropdown = ({ children, isActive }) => {
    return (
        <ul className={`dropdown shadow-sm ${isActive ? 'dropdown--active' : 'dropdown--inactive'}`}>
            {children}
        </ul>
    )
}

export default Dropdown