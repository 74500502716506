import { Link } from "react-router-dom"


const BrandContactItem = ({ url, icon }) => {
    return (
        <Link to={{ pathname: url }} target="_blank">
            <div className="contact-item d-flex flex-row justify-content-start align-items-center">
                <div className="contact-item__icon">
                    {icon}
                </div>
                <div className="contact-item__text">
                    {url}
                </div>
            </div>
        </Link>
    )
}

export default BrandContactItem