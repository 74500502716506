import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton"


const Terms = () => {
    return (
        <div className="container d-flex justify-content-center">
            <ScrollTopButton />
            <section className="terms d-flex flex-column justify-content-center">
                <div className="terms__main-header">
                    <h1>REGULAMIN</h1>
                </div>
                <div className="terms__sub-header">
                    <h4>z dnia 21.11.2021</h4>
                    <h3>www.slowfashionpolska.com</h3>
                </div>

                <div className="terms__paragraph">
                    <div className="terms__paragraph-header">
                        <h4>&sect; 1</h4>
                        <h5>Preambuła</h5>
                    </div>
                    <ol className="terms__paragraph-list">
                        <li>Dokument ten określa warunki korzystania ze strony internetowej i zwany będzie dalej Regulaminem.</li>
                        <li>Korzystając ze strony internetowej, użytkownik zobowiązany jest do zapoznania się treścią Regulaminu oraz przestrzegania go.</li>
                        <li>Użyte na stronie internetowej nazwy firm, ich logo oraz nazwy handlowe należą do ich właścicieli i mogą być zastrzeżone znakami towarowymi. Na stronie internetowej używane są tylko i wyłącznie w celu identyfikacji firmy.</li>
                        <li>Nieuprawnione korzystanie z zawartości strony internetowej jest zabronione, ponieważ takie działanie może naruszać prawa autorskie i/lub znaki towarowe.</li>
                        <li>Pytanie, uwagi i wszelkie wątpliwości mogą być zgłaszane pod następujący adres e-mail: slowfashionpl@gmail.com.</li>
                    </ol>
                </div>
                <div className="terms__paragraph">
                    <div className="terms__paragraph-header">
                        <h4>&sect; 2</h4>
                        <h5>Definicje</h5>
                    </div>
                    <ol className="terms__paragraph-list">
                        <li>Strona Internetowa – narzędzie służące do świadczenia usług elektronicznych o nazwie: www.slowfashionpolska.com.</li>
                        <li>Kontrahent – właściciel polskiej firmy, polski przedsiębiorca, który świadczy usługi lub oferuje towary, których zdjęcia, reklamy i wszelkie treści, sporządzone przez niego samodzielnie, znajdują się na Stronie Internetowej w postaci odnośników, linków i adresów www. </li>
                        <li>Usługa Elektroniczna – usługa świadczona drogą elektroniczną w postaci umieszczenia na Stronie Internetowej zdjęć, reklam i wszelkich treści a także odnośników, służących opisowi usług lub towarów oferowanych przez Kontrahentów, mających na celu promocję ich działalności, udostępniona Usługobiorcy w sposób nieodpłatny.</li>
                        <li>Usługobiorca - osoba fizyczna, prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, korzystająca z Usługi Elektronicznej.</li>
                        <li>Właściciel – podmiot udostępniający niniejszą stronę internetową- Renata Muzińska.</li>
                    </ol>
                </div>
                <div className="terms__paragraph">
                    <div className="terms__paragraph-header">
                        <h4>&sect; 3</h4>
                        <h5>Usługa elektroniczna</h5>
                    </div>
                    <ol className="terms__paragraph-list">
                        <li>Przedmiotem Usługi Elektronicznej jest promowanie działalności polskich przedsiębiorców i rękodzielników poprzez prezentowanie Usługobiorcom informacji na ich temat i promowanie ich usług oraz towarów.</li>
                        <li>Usługa Elektroniczna jest nieodpłatna.</li>
                        <li>Usługa Elektroniczna polega na publikowaniu na Stronie Internetowej danych, informacji i opisów, zdjęć oraz reklam oferowanych przez Kontrahentów usług i produktów, a także informacji handlowych (Ustawa o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002r. Dz. U. 2020 poz. 344.). </li>
                        <li>W ramach Usługi Elektronicznej, na Stronie Internetowej, publikowane są odnośniki (linki) do stron internetowych Kontrahentów. </li>
                        <li>Za treści zamieszczone w witrynach internetowych Kontrahentów, Właściciel nie ponosi odpowiedzialności w żadnym zakresie- również w zakresie przepisania ich na Stronie Internetowej.</li>
                        <li>Właściciel nie ponosi odpowiedzialności za niewykonanie lub też nienależyte wykonanie umowy zawartej między Kontrahentem a Usługobiorcą – w żadnym zakresie.</li>
                    </ol>
                </div>
                <div className="terms__paragraph">
                    <div className="terms__paragraph-header">
                        <h4>&sect; 4</h4>
                        <h5>Polityka prywatności</h5>
                    </div>
                    <ol className="terms__paragraph-list">
                        <li>Poprzez pliki Cookies należy rozumieć dane informatyczne, w szczególności pliki tekstowe, zapisywane i przechowywane w urządzeniach końcowych Usługobiorców, przeznaczonych do korzystania ze stron internetowych.</li>
                        <li>Stosowane przez Właściciela pliki Cookies są bezpieczne dla urządzenia Usługobiorcy i zawierają z reguły: nazwę domeny, z której pochodzą, czas przechowywania na urządzeniu i unikalny numer. Właściciel nie pobiera od Usługobiorcy żadnych innych informacji w sposób automatyczny.</li>
                        <li>Pliki Cookies nie mają możliwości pobierania żadnych danych osobowych, ani informacji poufnych z urządzeń Usługodawcy. </li>
                        <li>Cookies umieszczane przez Właściciela wykorzystywane są do:
                            <ol className="terms__paragraph-nested-list">
                                <li>zapewnienia bezpieczeństwa Strony Internetowej</li>
                                <li>optymalizacji i poprawy wydajności Usługi Elektronicznej</li>
                                <li>podniesieniu funkcjonalności i zapewnieniu niezawodności Strony Internetowej i poprawnej konfiguracji poszczególnych funkcji.</li>
                                <li>dopasowanie zawartości Strony Internetowej do indywidualnych potrzeb i preferencji Usługobiorcy.</li>
                                <li>tworzenia anonimowych statystyk</li>
                            </ol>
                        </li>
                        <li>Usługobiorca może zablokować automatyczne umieszczanie plików cookies na swoim urządzeniu, jednak może to prowadzić do niepoprawnego działania Strony Internetowej a w rezultacie do niemożliwości korzystania z niej.</li>
                        <li>Dane osobowe Usługobiorcy przetwarzane są przez Właściciela tylko i wyłącznie po uzyskaniu zgody Usługobiorcy i wyłącznie w przypadkach, gdy prawo upoważnia Właściciela do przetwarzania tych danych.</li>
                        <li>Zgodnie z przepisami ustawy o ochronie danych osobowych z dnia 10.05.2018r. (Dz. U. 2019 poz. 1781) Właściciel nie udostępni ani nie sprzeda stronie trzeciej danych osobowych Usługobiorcy.</li>
                    </ol>
                </div>
                <div className="terms__paragraph">
                    <div className="terms__paragraph-header">
                        <h4>&sect; 5</h4>
                        <h5>Reklamacje</h5>
                    </div>
                    <ol className="terms__paragraph-list">
                        <li>Reklamacje, skargi oraz uwagi dotyczące nieprawidłowego funkcjonowania Strony Internetowej powinny być niezwłocznie zgłaszane przez Usługobiorców na adres elektroniczny Właściciela tj. slowfashionpl@gmail.com</li>
                        <li>Na ustosunkowanie się do treści każdego zgłoszenia, Właściciel ma 21 dni roboczych od dnia jego otrzymania.</li>
                    </ol>
                </div>
                <div className="terms__paragraph">
                    <div className="terms__paragraph-header">
                        <h4>&sect; 6</h4>
                        <h5>Wymagania techniczne</h5>
                    </div>
                    <ol className="terms__paragraph-list">
                        <li>Niezbędne wymagania techniczne, konieczne do optymalnego korzystania ze Strony Internetowej i Usługi Elektronicznej:
                            <ol className="terms__paragraph-nested-list">
                                <li>korzystanie z platform systemów operacyjnych: MS Windows Vista, MS Windows 7 i 8, MS Windows XP SP-3, MacOS X 10.4.8 lub ich nowsze wersje, na dowolnego typu komputerze spełniającego wymogi ww. systemów operacyjnych oraz poprzez dowolne typy połączeń internetowych o przepustowości co najmniej 700 kilobitów/s,</li>
                                <li>korzystanie z przeglądarek internetowych: aktualne zalecane wersje przeglądarek Microsoft Internet Explorer, Mozilla FireFox, Apple Safari oraz Google Chrome.</li>
                            </ol>
                        </li>
                        <li>Właściciel nie ponosi odpowiedzialności za nienależyte świadczenie Usługi Elektronicznej na rzecz Usługobiorcy, spowodowane  niespełnieniem przez niego wymogów technicznych.</li>
                        <li>Zakazane jest dostarczanie przez Usługobiorcę treści o charakterze bezprawnym o których mowa w art. 8 ust. 3 pkt 2 lit. b) ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002r. (Dz. U. 2020 poz. 344), przesyłania takich treści na adres Właściciela oraz ingerowanie w sposób niezgodny z prawem w zawartość lub technologię Strony Internetowej.  </li>
                        <li>Każde wejście Usługobiorcy na Stronę Internetową jest równoznaczne z wyrażeniem przez niego zgody na świadczenie na rzecz Usługobiorcy Usługi Elektronicznej.</li>
                        <li>Właściciel nie określa warunków zawierania oraz rozwiązywania umów pomiędzy Kontrahentami a Usługobiorcami.</li>
                    </ol>
                </div>
                <div className="terms__paragraph">
                    <div className="terms__paragraph-header">
                        <h4>&sect; 7</h4>
                        <h5>Postanowienia końcowe</h5>
                    </div>
                    <ol className="terms__paragraph-list">
                        <li>Niniejszy Regulamin jest regulaminem, o którym mowa w art. 8 ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002r. (Dz. U. 2020 poz. 344)</li>
                        <li>Właściciel ma prawo do zmiany Regulaminu w dowolnym momencie.</li>
                        <li>W kwestiach nieujętych w Regulaminie, zastosowanie znajdują właściwe przepisy:
                            <ol className="terms__paragraph-nested-list">
                                <li>ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002r. (Dz. U. 2020 poz. 344)</li>
                                <li>ustawy o prawie autorskim i prawach pokrewnych z dnia 4 lutego 1994 r. (Dz. U. 2021  poz. 1062)</li>
                                <li>ustawy Kodeks Cywilny z dnia 23 kwietnia 1964r. (Dz. U. 2021 poz. 1509).</li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </section>
        </div>
    )
}

export default Terms
